import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '@components/Layout';
import Seo from '@components/Seo';
import Gutters from '@components/Gutters';
import MaxContent from '@components/MaxContent';
import SectionTitle from '@components/SectionTitle';
import * as styles from './404.module.scss';

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle} nobackground>
      <Seo title="404: Not Found" />
      <MaxContent>
        <Gutters>
          <h1 className={styles.notfound__title}>404</h1>
          <SectionTitle>Oops! That page can&apos;t be found.</SectionTitle>
        </Gutters>
      </MaxContent>
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
